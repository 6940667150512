<template>
   <v-container>
      <v-row class="top-nav">
         <v-col cols="5" class="title label-color">
            <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile" @click.stop="$store.commit('toggleDrawer')"></v-app-bar-nav-icon>
            <span class="text-capitalize">{{title}}</span>
         </v-col>
         <v-spacer />
         <v-col cols="1">
            <v-btn icon color="green" @click="openFm()">
               <v-icon>mdi-plus</v-icon>
            </v-btn>
         </v-col>
      </v-row>
      <v-data-table
         :headers="[...header, { text: 'Action', value: 'act', sortable: false }]"
         :items="items"
         item-key="id"
         :page.sync="page"
         :loading="loading"
         :footer-props="footer"
         :items-per-page="limit"
         :sort-by.sync="sort_by"
         :sort-desc.sync="sort_desc"
      >
         <template v-for="i in maped_item" v-slot:[i.key]="{ item }">
            {{ item[i.field] && fk[i.field].find(x => x.id === item[i.field]) }}
         </template>
         <template v-slot:[`item.act`]="{ item }">
            <v-btn class="hidden-sm-and-down" outlined fab x-small color="primary" @click="openFm(item)" title="edit"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn class="hidden-sm-and-down mx-1" outlined fab x-small color="red" @click="del(item)"><v-icon>mdi-delete</v-icon></v-btn>
            <v-btn class="hidden-md-and-up" icon color="blue" @click="more={...item}" title="pilihan"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
         </template>
      </v-data-table>
      <v-bottom-sheet :value="!!more.id" inset @click:outside="more={}">
         <v-sheet class="text-center" height="200px">
            <div class="headline py-4">{{more.nama}}</div>
            <v-divider class="mb-4 mx-3"/>
            <v-btn outlined color="primary" @click="openFm(more)"><v-icon>mdi-pencil</v-icon> edit</v-btn>
            <v-btn class="mx-2" outlined color="indigo" @click="reset(more)"><v-icon>mdi-key-change</v-icon> reset password</v-btn>
            <!-- <v-btn outlined color="red" @click="del(more)"><v-icon>mdi-delete</v-icon> hapus</v-btn> -->
         </v-sheet>
      </v-bottom-sheet>
      <v-dialog :value="!!fm.id" max-width="530" @click:outside="closeFm" >
         <v-card>
            <v-card-title class="label-color text-capitalize">{{fm.id=='new'? 'Tambah':'Edit'}} {{title}}</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
               <v-form ref="form">
                  <template v-for="(f,i) in header">
                     <v-select v-if="fk[f.value]" :label="f.text" v-model="fm[f.value]" :items="fk[f.value]" :item-text="f.text" :item-value="f.value" :key="i"/>
                     <v-text-field v-else :label="f.text" v-model="fm[f.value]" :key="i" />
                  </template>
               </v-form>
            </v-card-text>
            <v-divider else class="mx-4"></v-divider>
            <v-card-actions>
               <v-spacer />
               <v-btn color="#aaa" outlined rounded @click="closeFm">close</v-btn>
               <v-btn color="primary" :loading="on_submit" outlined rounded @click="save">save</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-container>
</template>
<script>
import {get, create, edit} from '../api/master';
const modul = ['area','zona','sla','roles', 'task_stat', 'branch', 'client', 'client_mail'];

export default {
   data: () => ({
      title: null,
      loading: false,
      show_filter: false,
      search: null,
      filter: {},
      items: [],
      maped_item: [],
      footer: {
         'items-per-page-options': [10,30,50],
         showFirstLastPage: true,
         firstIcon: 'mdi-arrow-collapse-left',
         lastIcon: 'mdi-arrow-collapse-right',
         prevIcon: 'mdi-minus',
         nextIcon: 'mdi-plus'
      },
      page: 1,
      sort_by: 'id',
      sort_desc: true,
      limit: 15,
      total: 0,
      header: [],
      fm: {},
      more: {},
      fk: {},
      on_submit: false,
      status_opt: [{text:'Aktif',value:'0'},{text:'Non Aktif',value:'1'}]
   }),
   mounted() {
      this.checkRoute();
   },
   methods: {
      checkRoute() {
         const mod = this.$route.params.mod;
         if(modul.indexOf(mod) === -1) return;
         this.title = mod;
         this.getData();
      },
      getData() {
         this.loading = true;
         get(this.title).then(rsl => {
            const hdr = rsl.hdr.split(',');
            const typ = rsl.typ.split(',');
            this.header = rsl.fld.split(',').map((x,i) => ({ text: hdr[i], value: x, typ: typ[i]}));
            this.items = rsl.data;
            this.fk = rsl.fk || {};
            this.maped_item = Object.keys(rsl.fk).map(x => ({key: 'item.'+ x, field: x}));
         }).finally(() => { this.loading = false });
      },
      openFm(dat) {
         this.fm = {...(dat || {id: 'new'})};
      },
      closeFm() {
         this.$refs.form.reset();
         this.fm = {};
      },
      save() {
         if(!this.$refs.form.validate()) return false;
         const {id, ...data} = this.fm;
         this.on_submit = true;

         (id == 'new' ? create(data) : edit(id, data)).then(rsl => {
            if(rsl.stat == 1) {
               this.$store.dispatch('alert', { title: 'Success', text: rsl.mess, type: 'success', showConfirmButton: false, timer: 3000})
               .then(() => {
                  this.getData();
                  this.closeFm();
               });

            } else this.$store.dispatch('alert', {title: 'Ooops!', text: rsl.mess, type: 'warning'});
         }).finally(() => { this.on_submit = false });
      },
      del(usr) {
         this.$store.dispatch('alert', { text: `Yakin akan menghapus akun ${usr.nama}?`, type: 'question', showCancelButton: true })
         .then(fb => {
            if(fb.value) {
               // this.$api.crud.create(`user/${uid}/reset_password`).then(rsl => {
               //    if(rsl.error) this.$store.dispatch('alert', {title: 'Ooops!', text: rsl.error, type: 'error'});
               //    if(rsl.success) this.$store.dispatch('alert', { title: 'Success', text: rsl.success, type: 'success', timer: 2000, showConfirmButton: false});
               // });
            }
         });
      },
   },
   watch: {
      '$route.params.mod': 'checkRoute',
   },
}
</script>
