import axios from 'axios';
import CFG from '../config';

export function create(mod, data) {
   return new Promise((res, rej) => {
      axios.post(CFG.api_url +'/master/'+ mod, data).then(({data}) => {
         res(data);
      }).catch(e => {
         console.log('create-user api err:', e);
         rej(e);
      })
   });
}

export function get(mod, filter) {
   return new Promise((res, rej) => {
      let url = CFG.api_url +`/master/${mod}?`;
      for(let i in filter) {
         url += `&${i}=${filter[i]}`;
      }
      axios.get(url).then(({data}) => {
         res(data);
      }).catch(e => {
         console.log('get user api err:', e);
         rej(e);
      })
   });
}

export function edit(id, data) {
   return new Promise((res, rej) => {
      axios.put(CFG.api_url +'/master/'+ id, data).then(({data}) => {
         res(data);
      }).catch(e => {
         console.log('create-user api err:', e);
         rej(e);
      })
   });
}
